<template>
    <modal name="ModalQrCode" height="auto" width="512" :scrollable="true"  :clickToClose="false"  > 
        <CloseModal :close="onClose" />
        <div class="modalbox">
          <div class="modalbox-content text-center" >
              <div class="row justify-content-md-center">
                <div class="form-group">
                  <div class="col-md-auto">
                    <img :src="'data:image/jpeg;base64,' + dataQrCode.qrcode" >
                  </div>
                </div>
              </div>
          </div>
        </div>
      <div class="container text-left">
        <div class="row">
          <div class="col">
            <h2><strong> Para usar o BD Vendas no seu dispositivo móvel:</strong></h2>
            <p>1 - Abra o aplicativo BD Vendas</p>
            <p>2 - No modo de configuração, selecione "Ler de QRCode"</p>
            <p>3 - Aponte a câmera do dispotivo para o Qrcode para capturar o código</p>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button class="button" v-on:click="onClose">Fechar</button>
      </div>
    </modal>

</template>

<script>
import CloseModal from "../../../components/CloseModal";


export default {
  name: "ModalQrCode",
  components: { CloseModal  },
  props: ["dataQrCode", "onClose"],
  data() {
    return {
      loading: false,
      teste : `Acesso administrativo ${dataQrCode.qrcode}`
      
    };
  },
};
</script>