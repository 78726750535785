<style scoped>
table:first-child {
  margin-top: 20px;
}
.title {
  padding: 4px 0;
  margin: 0px;
}
th small {
  font-weight: lighter;
  color: #999999;
  font-size: 12px;
}
</style>

<template>
    <div>
        <modal name="themeEdit" height="auto" width="90%" :scrollable="true" :clickToClose="false">
            <CloseModal :close="closeEdit" />
            <div class="modalbox">
                <div class="modalbox-content" v-if="currentTheme">
                    <CTabs variant="tabs" class="nav-tabs-boxed">
                        <CTab :title="label(group)" v-for="(schema, group) in (currentTheme.schema.paleta)" :key="group">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>
                                            <h2 class="title">{{ label(group) }}</h2>
                                            <small>{{ description(group) }}</small>
                                        </th>
                                        <th class="center"></th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(color, action) in schema">
                                        <td>
                                            <div><strong>{{ label(action) }}</strong></div>
                                            <div><small>{{ description(action) }}</small></div>
                                        </td>
                                        <td class="right">
                                            <InputColorPicker v-model="currentTheme.schema.paleta[group][action]"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </CTab>
                    </CTabs>
                </div>
            </div>
            <div class="modal-footer">
                <button :class="{ 'button button-success': true, 'button-disabled': false }" v-on:click="update">
                    Salvar Alterações
                </button>
                <button class="button" v-on:click="closeEdit">Fechar</button>
                <button class="button pull-right" v-on:click="reset">Resetar para padrão</button>
            </div>
        </modal>

        <div class="card-body">
            <div class="xrow">
                <div v-for="tema in temas.temas" class="col col-1-3">
                    <div class="thumbnail BorderActive">
                        <div class="subtitle ">
                            Sistema: <strong class="label dark">{{ tema.sistema }}</strong>
                        </div>
                        <div class="LogoimgBox">
                            <img :src="iconPalette" />
                        </div>
                        <div class="caption">
                            <div class="caption__actions">
                                <label class="button success" v-on:click="openEdit(tema)">
                                    Personalizar
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import clone from "just-clone";
import iconPalette from "@/assets/palette.png";
import ColorPicker from "../../components/colorPicker";
import InputColorPicker from "../../components/InputColorPicker";
import CloseModal from "../../components/CloseModal";

const initialState = {
  schema: {
    paleta: [],
  },
};

export default {
  name: "temas",
  components: {
    InputColorPicker,
    ColorPicker,
    CloseModal,
  },
  props: ["temas", "salvarTema"],
  data() {
    return {
      iconPalette,
      currentTheme: { ...initialState },
    };
  },
  methods: {
    closeEdit() {
      this.$modal.hide("themeEdit");
    },
    openEdit(tema) {
      this.currentTheme = clone(tema);
      this.$modal.show("themeEdit");
    },
    update() {
      this.salvarTema(this.currentTheme).then(() => {
        this.currentTheme = { ...initialState };
        this.closeEdit();
      });
    },
    reset() {
      const schema = clone(this.temas.default);
      this.currentTheme.schema = schema;
    },
    label(action) {
      return this.temas.labels[action]?.label || action.to;
    },
    description(action) {
      return this.temas.labels[action]?.desc || action;
    },
  },
};
</script>
