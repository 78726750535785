<template>
  <div v-if="advancedConfigs.length">
    <ModalInfoMarkdown
        :titulo="currentConfig.desc"
        :conteudo="currentConfig.documentacao"
        :close="closeInfo"
    ></ModalInfoMarkdown>
    <component :is="currentComponent" :config="currentConfig" :update="update" :close="closeEdit"></component>
    <div class="filters row" style="margin-top:0">
      <div class="col-1-1">
        <span class="filters__search">
          <input
              type="text"
              v-model="termo"
              placeholder="Filtrar configuração por ..."
          >
          <img
              style="position: relative; right: -10px;"
              src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
          >
        </span>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th>Descrição</th>
          <th>Valor</th>
          <th>Título</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="config in filteredConfigs" v-bind:key="config.chave">
          <td>{{ config.desc }}</td>
          <td
              v-if="config.tipo !== 'WYSIWYG'"
          >{{ config.valor | tipo(config.tipo, config.opcoes) }}
          </td>
          <td v-if="config.tipo === 'WYSIWYG'">
            <div v-html="config.valor"></div>
          </td>
          <td>
            {{ config | valorParametro }}
          </td>
          <td class="actions">
            <button
                v-show="config.has_documentacao"
                type="button"
                :class="{'button button-info': true, 'button-disabled':!config.has_documentacao}"
                v-on:click="openInfo(config)" title="Informações detalhadas">
              <fa-icon icon="info-circle"/>
            </button>
            <button type="button" class="button button-primary" v-on:click="openEdit(config)">
              <fa-icon icon="pencil-alt"/>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import clone from 'just-clone';
import {arrayFilter} from '../../helpers/utils';
import ModalInfoMarkdown from '@/components/ModalInfoMarkdown';
import {get} from '@/helpers/apiRequest';

export default {
  name: 'avancado',
  components: {
    ModalInfoMarkdown,
  },
  props: ['advancedConfigs', 'updateConfig'],
  data() {
    return {
      termo: '',
      currentComponent: null,
      currentConfig: {}
    };
  },
  computed: {
    filteredConfigs() {
      return arrayFilter(this.advancedConfigs, this.termo, ['desc', 'chave']);
    },
  },
  filters: {
    valorParametro(parametro) {
      if (parametro.tipo === 'TYPE_KEYVALUE') {
        return 'Múltiplas opções';
      }
      return parametro.valor && parametro.valor.label || 'N/A';
    },
    tipo(valor, tipo, opcoes) {
      switch (tipo) {
        case 'SELECT':
          return opcoes[valor] || valor;

        case 'BOOLEAN':
          if (valor) return 'Sim';
          return 'Não';

        case 'TEL':
        case 'EMAIL':
          return `${valor.valor}`;

        case 'INTEGRATION':
        case 'JSON':
          return '';

        case 'TYPE_KEYVALUE':
          return 'Múltiplas opções';

        default:
          return valor;
      }
    }
  },
  methods: {
    update(config) {
      this.updateConfig(config).then(() => this.closeEdit());
    },
    importComponent(componentName, modalName = null) {
      return import(`${componentName}`)
          .then((component) => {
            this.currentComponent = component.default;
            setTimeout(() => this.$modal.show(modalName || componentName), 0);
          });
    },
    openInfo(config) {
      this.currentConfig = clone(config);
      this.loadParametroDetalhes(config);
      this.$modal.show('modalInfoMarkdown');
    },
    closeInfo() {
      this.$modal.hide('modalInfoMarkdown');
    },
    openEdit(config) {
      this.currentConfig = clone(config);

      this
          .importComponent(`./Avancado/${config.chave}`, config.chave)
          .catch(() => this.importComponent('./Edit', 'configEdit'));
    },
    closeEdit() {
      this.$modal.hide(this.currentComponent.name);
    },
    loadParametroDetalhes() {
      this.loading = true;

      get(`/admin/parametros/${this.currentConfig.chave}`)
          .then((json) => {
            this.currentConfig = json;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
    },
    mounted() {
      console.log(this.advancedConfigs);
    }
  }
};
</script>
